import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiQuery, blobResponseHandler } from 'shared/helpers/api';
import { convertToEditableFormModel } from './helpers';
import { enqueueSnackbar } from 'notistack';
import { getErrorFromException } from 'core/utils/errorHandling';
import { priceListActions } from './priceList.state';
import { defaultValues as priceListDefaultValues } from '../components/PriceListEditDialog/PriceListEditDialog.yup';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { t } from 'shared/translations';
export const api = createApi({
    reducerPath: 'api/priceLists',
    tagTypes: ['PriceList', 'Articles'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getPriceLists: builder.query({
            query: () => ({
                method: 'GET',
                url: 'exportpricelist',
            }),
            transformResponse: (response) => response.map((priceList) => ({ ...priceList, lastUpdate: new Date(priceList.lastUpdate) })),
            providesTags: (result, error, arg) => result ? [...result.map(({ exportPriceListId }) => ({ type: 'PriceList', id: exportPriceListId })), 'PriceList'] : ['PriceList'],
        }),
        setPriceListActive: builder.mutation({
            query: ({ priceListId, isActive }) => ({
                method: 'POST',
                url: 'exportpricelist/activity',
                //body,
                body: {
                    exportPriceListId: priceListId,
                    isActive,
                },
            }),
            // invalidatesTags: (result, error, { priceListId }) => [{ type: 'PriceList', priceListId }],
            onQueryStarted: async (request, { dispatch, queryFulfilled, getState }) => {
                const { priceListId, isActive } = request;
                try {
                    await queryFulfilled;
                    const patchResult = dispatch(api.util.updateQueryData('getPriceLists', undefined, (draft) => {
                        const patch = draft.map((item) => ({
                            ...item,
                            emails: [...item.emails],
                            includedPricelists: [...item.includedPricelists],
                            scheduleTime: [...item.scheduleTime],
                            isActive: item.exportPriceListId === priceListId ? isActive : item.isActive,
                        }));
                        Object.assign(draft, patch);
                    }));
                }
                catch {
                    // mb move snackbar to here?
                    // TODO: to delete this comments later. This is to be used with optimistic update.
                    // patchResult.undo();
                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                    dispatch(api.util.invalidateTags(['PriceList']));
                }
            },
        }),
        // downloadPriceList: builder.query<void, { priceListId: number }>({
        // 	query: ({ priceListId }) => ({
        // 		method: 'GET',
        // 		// url: 'exportpricelist/file?id=110',
        // 		url: 'exportpricelist/file',
        // 		params: { id: priceListId },
        // 		body: { id: priceListId },
        // 	}),
        // }),
        downloadPriceList: builder.mutation({
            queryFn: async ({ priceListId }, api, extraOptions, baseQuery) => {
                const result = (await baseQuery({
                    url: 'exportpricelist/file',
                    params: { id: priceListId },
                    cache: 'no-cache',
                    responseHandler: blobResponseHandler,
                }));
                // TODO: use downloadBlob here
                if (result.error) {
                    enqueueSnackbar({
                        variant: 'error',
                        header: t('file.download.failed'),
                        body: getErrorFromException(result.error),
                        persist: true,
                    });
                    return { data: null };
                }
                const hiddenElement = document.createElement('a');
                const url = window.URL || window.webkitURL;
                const priceListBlob = url.createObjectURL(result.data);
                hiddenElement.href = priceListBlob;
                hiddenElement.target = '_blank';
                hiddenElement.download = `Прайс_${priceListId}.xlsx`;
                hiddenElement.click();
                URL.revokeObjectURL(priceListBlob);
                return { data: null };
            },
        }),
        getPriceList: builder.query({
            queryFn: async (priceListId, { dispatch }, extraOptions, baseQuery) => {
                // `priceListId` is empty on new price list creation.
                const priceListTask = priceListId ? baseQuery({ method: 'GET', url: 'exportpricelist', params: { id: priceListId } }) : undefined;
                const priceListPricesForExportTask = baseQuery({
                    method: 'GET',
                    url: 'exportpricelist/prices4export',
                });
                const [priceListResult, priceListPricesForExportResult] = await Promise.all([priceListTask, priceListPricesForExportTask]);
                if (priceListResult?.error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.pricelist.request.failed'), error: priceListResult.error }));
                    return priceListResult;
                }
                if (priceListPricesForExportResult.error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.pricelist.prices.for.export.data.request.failed'), error: priceListPricesForExportResult.error }));
                    return priceListPricesForExportResult;
                }
                const priceList = priceListResult?.data;
                const priceListsForExport = priceListPricesForExportResult.data;
                dispatch(priceListActions.setInitialIsFtpVerified(priceList?.useFtp ? true : undefined));
                const { includedPriceListsCombined, ...defaultValues } = priceListDefaultValues;
                const emptyPriceList = { ...defaultValues, includedPricelists: [] };
                return { data: convertToEditableFormModel(priceListsForExport, priceList ?? emptyPriceList) };
            },
            providesTags: (result, error, id) => [{ type: 'PriceList', id }],
        }),
        updatePriceList: builder.mutation({
            query: ({ priceList }) => ({
                method: 'POST',
                url: 'exportpricelist',
                body: priceList,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('update.pricelist.request.failed'), error }));
                }
            },
            invalidatesTags: (result, error, { priceList }) => error
                ? []
                : priceList.exportPriceListId
                    ? [{ type: 'PriceList', id: priceList.exportPriceListId }]
                    : [{ type: 'PriceList' }],
        }),
        deletePriceList: builder.mutation({
            query: ({ priceListId }) => ({
                method: 'DELETE',
                url: 'exportpricelist',
                params: { id: priceListId },
            }),
            invalidatesTags: (result, error, { priceListId }) => [{ type: 'PriceList', id: priceListId }],
        }),
        // TODO: to delete later. The endpoint is no longer needed since all required data is being gotten during `getPriceList` endpoint workflow.
        // getPriceListPricesForExport: builder.query<IPriceListForExport[], void>({
        // 	query: () => ({
        // 		method: 'GET',
        // 		url: 'exportpricelist/prices4export',
        // 	}),
        // 	transformResponse: (response: (Omit<IPriceListForExport, 'updated'> & { updated: string })[]) =>
        // 		response
        // 			.map((priceList) => ({ ...priceList, updated: new Date(priceList.updated) }))
        // 			.sort((a, b) => (a.name || '').localeCompare(b.name || '')),
        // }),
        checkFtpConnection: builder.query({
            query: (body) => ({
                method: 'POST',
                url: 'ExportPricelist/CheckFtpConnection',
                body,
            }),
        }),
        // TODO: extract this method to new unique articles API, since it used not only in PriceLists but also in Stock, etc.
        getArticlesFiltered: builder.query({
            query: ({ search }) => ({
                method: 'GET',
                url: 'ExportPricelist/articles',
                params: { filter: search },
            }),
            // TODO: check later. For some reason this `providesTags` for `Articles` tag doesn't work. It doesn't refresh anything probably of `search` lack.
            // ServerSearchAutocompleteSimple (in StockItemAddDialog) has to use refetchOnMountOrArgChange because of this.
            providesTags: ['Articles'],
        }),
        // TODO: Refactor later, when new api is done.
        // This method from old Backend is already existed in brands api and returns array of objects.
        // This version returns array of string.
        getBrandsAsStringArrayFiltered: builder.query({
            query: ({ search }) => ({
                method: 'GET',
                url: 'ExportPricelist/brands',
                params: { filter: search },
            }),
        }),
    }),
});
export const { useGetPriceListsQuery, useGetPriceListQuery, useSetPriceListActiveMutation, useDownloadPriceListMutation, useUpdatePriceListMutation, useDeletePriceListMutation, useLazyCheckFtpConnectionQuery, useGetArticlesFilteredQuery, useGetBrandsAsStringArrayFilteredQuery, } = api;
export { api as priceListApi };
